.accordion_block {
    display: flex;
    justify-content: space-around;
    
}
.analysis_mom_trend_title{
    font-size: 28px;
    color:#152550;
    font-weight: 400;
}
.accordion_body {
    display: flex;
    row-gap: 0;
    gap:50px;
    row-gap:5px;
    padding-top: 10px;
    flex-wrap: wrap !important;
}

.analysis_category {
    display: flex;
    flex-direction: column;
}

.analysis_title {
    display: flex;
    font-weight: 500;
    font: "Roboto";
    font-size:18px;
    margin-bottom: 5px;
    color:#152550;
}

.analysis_body {
    display: flex;
}
.analysis_body_cards {
    display: flex;
    margin-top:30px;
    gap:20px;

}

.green_arrow {
    color: #3FCC8E;

}

.red_arrow {
    color: #B20625;

}
.yellow_arrow {
    color: rgb(255, 217, 0);

}

.bold {

    font-weight: bold;
}
tbody>tr:nth-of-type(1)>td{
    font-size:16px;
    font-weight: 500;
}
td {
    text-align: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
    color:#706B64 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    font-weight: 400;
    min-width:150px;
}
tbody{
    padding:20px !important;
    background-color: white;
    display:table-cell !important;
}

.rotate135 {
    rotate: +135deg;
}

.rotate45 {
    rotate: +45deg;
}

.accordion_trends {
    margin: 10px
}

.analysis_card {
    margin-top: 10px
}

.form-select {
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.accordion-button {
    background-color: rgb(85, 85, 85) !important;
    max-height: 20px;
    font-weight: 500;
    color: white !important;

}

.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.custom-select__control{
    width:500px
}

.custom-option {
    transition: background 60ms;
  
}

.custom-option:hover {
    transition-delay: 60ms;
    background: #deebff;
}

.custom-option.custom-select__option--is-focused {
    background: none;
}

.custom-option.custom-select__option--is-selected {
    background: #2684FF;
}
.spinner_text{
    padding-left:20px
}

.card_scores{
    width:200px;
    height:58px;
    display: flex;
    flex-wrap: auto;
    text-wrap: auto;
    padding:10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(187, 187, 187);
}
.card_scores.green{
    background-color: #3FCC8E;
    color:#152550;
    font-size: 16px;
    font-weight: bold;
    border:none

}
.card_scores.red{
    background-color: #B20625;
    color:#FFFFFF;
    font-size: 16px;
    font-weight: bold;
    border:none

}
.card_scores.yellow{
    background-color: #FFAE00;
    color:#152550;
    font-size: 16px;
    font-weight: bold;
    border:none

}
.table_row_title{
    font-weight: 500;
}
