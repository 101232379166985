.dashboard_forms{
    display:flex;
    justify-content: center;
}
.reporting_forms {
    width: 100%;
    margin-top:30px;
    max-width: 550px;

    background-color: rgb(245, 245, 245);
}
.form-control{
    padding-top:0 !important;
    margin-top:0 !important
}
.edit_submit {
    display: flex;
    gap: 20px;
    margin-bottom:20px;
    justify-content: center;
}

.mb-3 {
    margin-bottom: 0;
    font-family: Roboto
}
.form_container{
    padding-left:10px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:20px;
    border:1px solid rgb(230, 227, 227);
    margin-right:5px
}
.speechIVR_form {
    height: 227px;
    margin-bottom:6px
}

.voicebot_form {
    height: 221px
}
textarea{
    margin-top:20px !important;
  
}
#cover-spinner{
    position:fixed;
    width:100%;
    left:0;
    right:0;
    top:90px;
    bottom:0;
    background-color: rgb(240, 240, 240);
    z-index:150
}
.spinnerclasss{
    z-index:9999 !important;
    position:fixed;
    left:50%;
    top:50%
}
div[data-tb-test-id='CancelDialog-Dialog-Floater']{
    top:100px !important;
}
.top_form{
    padding-left:10px;
    padding-right:10px;
    display: flex;
    flex-direction: column;
}

.features_container{
    width: 100%;
    max-width: 550px;
}

.floating-button {
    position: fixed;
    display: flex;
    gap:10px;
    bottom: 20px;
    right: 20px;
    margin: 10px;
    font-family: Roboto
  }
.feature_form{
    display: flex;
    flex-direction: column;
    /* min-height:240px; */
    height:100%;
}
.comments_text_box.form-control {
    display: flex;
    flex-grow: 1 !important;
    resize: none;
}
.selector{
    font-weight: 400 !important;
    margin-bottom:2px;
}
.date_selector{
    display: flex;
    justify-content: center;
    align-items: center;
}
.date_selector_text{
    min-width:150px
}
.updated_at{
    padding-left: 10px;
}