.checkMark{
color:rgb(143, 204, 50);
font-size: 40px;
margin-right:5px;
}
.feedbackSentText{
font-size: 25px;
}
.modal-dialog{
 max-width: 60% !important;   
width: 60% !important;
font-family: Roboto;  

}

.spinner-border{
    width:1.3rem !important;
    height:1.3rem !important;
    --bs-spinner-border-width:3px !important;
}