* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    width:100%;
    background-color: rgb(245, 245, 245) !important;
  }
  
.dashboards{
  display: flex;
  justify-content: center;
}
.filters{
  display: flex;
  height:125px;
  justify-content: space-between;
  align-items: end;
  border-radius: 5px 5px 0px 0px;
  background-color: #152550;
  border-left:rgb(196, 196, 196) 1px solid;
  border-right:rgb(196, 196, 196) 1px solid;
  border-top:rgb(196, 196, 196) 1px solid;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2) !important;
  padding-right:80px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-right:20px
  
}

.select-box{
  display:flex;
  margin-top:5px;
  margin-bottom:25px;
  margin-right:5px
}

.body_container{
  width:100%;

}
.accordion_box{
  display:flex;
  justify-content: center;

}
.analysis_section{
  display:flex;
  flex-direction: column;
  align-items: center;
  min-width:95%;
  width:90%;
  margin: 10px 5px 15px 20px
}
.analysis_card{
  min-width:95%;
  width:100%;
  margin: 10px 5px 15px 20px
}
.accordion{
  min-width:100%;
}


.accordion-body{
  display:flex;
  justify-content: center;
}
.select_label{

  width:150px;
  font-weight: 400;
  padding-top:10px
}
.spinnerclass{
  display:flex;
  justify-content: center;
}
.spinner_text{
  padding-left:20px;
  padding-top:5px
}
.hidden_tab{
  position: absolute;
  left:     -10000px;
  top:      auto;
  width:    1600px;
  height:   1600px;
  overflow: hidden;
}
.show_tab{
  display: flex;
  justify-content: center;
}

.card-header{
  font-weight: bold;
  text-decoration: underline;
}

.dashboard_container{
  max-height:100%;
  height:100%;

  border-radius: 5px;
  padding-bottom:10px;
  padding-top:10px;



}
.dashboard_container.center{
  align-items: center;
  justify-content: center;
  display:flex;
}
ul.tabs{
  border-bottom:#C5C5C5 1px solid;

}
.tabs>li>button.nav-link{
  background-color: rgb(245, 245, 245);
  color:rgb(110, 110, 110) !important;
  font-weight:400 !important;
  border:none;
  flex:none !important;
  padding:none;
  font-size: 20px !important;
  font:"Roboto"
}
.tabs>li>button.nav-link.active{
  background-color: rgb(245, 245, 245);
  color:#ff4f1f !important;
  border-bottom:#ff4f1f solid 2px;
}
.tabs>li>button.nav-link:hover{
  color:#ff4f1f !important;
}
.mb-3.tabs{
  background-color: rgb(245, 245, 245);
  border-radius: 0px 0px 5px 5px;
}
.card_site{
  display:flex;
  justify-content: center;
  align-items: center;
  width:120px;
  height:70px;

  color:white;
  font-size: 35px;
  margin-top:10px;
  margin-left:10px;
  margin-bottom:10px;
  font-family: 'Roboto';
  
}
.card_site>img{
 
  min-width: 100px;
  min-height: 100px;
  border-radius:10px;

}
.c360_logo{
  display:flex;
  align-items: center;
  flex:auto;
  min-height: 100%;
}
.site_name{
  color:white;
  margin-left:5px;
  font-size:30px;
  font:"Roboto";
}