html,
body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0
}
.genesys_bar{
    background-color: #F8F8F8;
    height: 25px;
    min-height: 25px;
}
.top_navbar {
    display: flex;
    justify-content: space-between;
    max-height: 50px;
    background-color: #F8F8F8;
    min-height: 60px;
}

.nav_btn{
    color:#F8F8F8;
    font-weight:bold;
    font-family: Roboto,sans-serif;
    list-style-type: none;
    padding:10px
}
.menu {
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;
    margin-right: 40px;

}

.customImgSize {
    width: auto;
    height: 40px;

}

.logo {
    display: flex;
    color: #152550 !important;
    align-items: center;
    padding: 5px 30px
}
.navbar {
    box-shadow: none;
    background-color: #F8F8F8 !important;
}
.nav-link{
    color:#706B64 !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    font-family: 'Roboto' !important;
}

.nav-link:hover{
   text-decoration: underline;
}
.dropdown-menu.show>.nav-link{
    color:#706B64 !important;
}
.dropdown-menu>.dropdown-menu-dark>.dropdown-toggle{
    color:#706B64 !important;
}
.nav-item{
    padding-left:10px;
    padding-right:5px;
}
.last-navbar-level{
    width:max-content
}
.last-navbar-level.active{
    border-radius: 10px !important;
    color:#FF451A !important

}

.dropdown-menu show{
    width:100%
}
.external_help_container{
    min-height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.help_container{
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-right:20px;
    cursor:pointer;
    background-color: #152550;
    border-radius: 80px;
    height:46px;
    width:46px;
   
}

